<template>
  <div class="business-upsert">
    <div class="col-md-12">
      <b-overlay
        :show="isSubmitting"
        no-wrap
      >
      </b-overlay>
      <KTCodePreview :title="bindingTitle">
        <template v-slot:preview>
          <b-form lazy-validation>
            <div class="row">
              <b-form-group class="col-3">
                <template>
                  <label class="font-weight-bolder">Mã số thuế:
                    <span class="text-danger font-size-md"> *</span></label>
                </template>
                <b-form-input
                  size="sm"
                  v-model.trim="$v.apiParams.taxNo.$model"
                  :state="validateState('taxNo')"
                  required
                  placeholder="Nhập Mã số thuế"
                  class="mb-3"
                  id="taxNo"
                  ref="taxNo"
                  aria-describedby="taxNoError"
                ></b-form-input>
                <b-form-invalid-feedback
                  aria-live="assertive"
                  id="taxNoError"
                >
                  <div v-if="!$v.apiParams.taxNo.required">
                    Yêu cầu mã số thuế
                  </div>
                  <div v-if="!$v.apiParams.taxNo.isValidTaxNo">
                    Mã số thuế không hợp lệ
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="col-3">
                <template>
                  <label>Số điện thoại:</label>
                </template>
                <b-form-input
                  size="sm"
                  v-model.trim="$v.apiParams.phoneNo.$model"
                  :state="validateState('phoneNo')"
                  placeholder="Nhập số điện thoại"
                  class="mb-3"
                  id="phoneNo"
                  ref="phoneNo"
                  aria-describedby="phoneNoError"
                ></b-form-input>
                <b-form-invalid-feedback
                  aria-live="assertive"
                  id="phoneNoError"
                  v-if="!$v.apiParams.phoneNo.isValidPhoneNumber"
                >Vui lòng nhập số điện thoại hợp lệ.</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="col-3">
                <template>
                  <label>Email:</label>
                </template>
                <b-form-input
                  size="sm"
                  v-model.trim="$v.apiParams.email.$model"
                  :state="validateState('email')"
                  placeholder="Nhập email"
                  class="mb-3"
                  id="email"
                  ref="email"
                  aria-describedby="emailError"
                ></b-form-input>
                <b-form-invalid-feedback
                  aria-live="assertive"
                  id="emailError"
                  v-if="!$v.apiParams.email.isValidEmail"
                >
                  Email không hợp lệ.
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <b-row>
              <b-col cols="6">
                <b-form-group>
                  <template>
                    <label class="font-weight-bolder">Tên công ty:
                      <span class="text-danger font-weight-bolder">*</span></label>
                  </template>
                  <b-form-textarea
                    v-model.trim="$v.apiParams.name.$model"
                    size="sm"
                    class="input-style"
                    placeholder="Nhập tên KH doanh nghiệp"
                    :state="validateState('name')"
                    :rows="6"
                    :max-rows="6"
                    id="name"
                    ref="name"
                    aria-describedby="nameError"
                  ></b-form-textarea>
                  <b-form-invalid-feedback
                    aria-live="assertive"
                    id="nameError"
                  >Vui lòng nhập tên khách hàng</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group>
                  <template>
                    <label class="font-weight-bolder">Địa chỉ:
                      <span class="text-danger font-weight-bolder">*</span></label>
                  </template>
                  <b-form-textarea
                    v-model.trim="$v.apiParams.address.$model"
                    size="sm"
                    class="input-style"
                    :placeholder="'Địa chỉ khách hàng'"
                    :state="validateState('address')"
                    :rows="6"
                    :max-rows="6"
                    id="address"
                    ref="address"
                    aria-describedby="addressError"
                  ></b-form-textarea>
                  <b-form-invalid-feedback
                    aria-live="assertive"
                    id="addressError"
                  >
                     <div v-if="!$v.apiParams.address.required">
                      Yêu cầu địa chỉ.
                  </div>
                  <div v-if="!$v.apiParams.address.maxlength">
                    Địa chỉ không được vượt quá 400 ký tự.
                  </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Ghi chú:">
                  <b-form-textarea
                    size="sm"
                    v-model.trim="apiParams.note"
                    :placeholder="'Thêm ghi chú'"
                    :rows="6"
                    :max-rows="8"
                    id="note"
                    ref="note"
                    aria-describedby="noteError"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
            </b-row>
          </b-form>
        </template>
        <template v-slot:foot>
          <b-button
            style="margin-right: 10px; font-weight: 600; width: 70px"
            :disabled="disableSubmit"
            variant="primary"
            size="sm"
            type="submit"
            @click.prevent="onUpsertBusiness"
          >{{ bindingTitleButton }}</b-button>
          <router-link :to="pathNameToListBusiness">
            <b-button
              style="font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
            >Hủy</b-button>
          </router-link>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { cloneDeep, makeToastFaile, makeToastSuccess } from '@/utils/common';
import { BUSINESS_STATUS } from '@/utils/enum';
import {
  isValidEmail,
  isValidPhoneNumber,
  isValidTaxNo,
} from '@/utils/validation';

const KTCodePreview = () => import(/* webpackPrefetch: true */ '@/view/content/CodePreview.vue');

export default {
  components: {
    KTCodePreview,
  },
  mixins: [validationMixin],
  props: ['id'],
  data() {
    const businessModel = {
      id: null,
      name: '',
      email: '',
      taxNo: '',
      phoneNo: '',
      address: '',
      note: '',
      status: BUSINESS_STATUS.ACTIVE,
    };
    return {
      pathNameToListBusiness: { name: 'list-business' },
      initDataApiParams: Object.assign({}, businessModel),
      apiParams: Object.assign({}, businessModel),
      archivedBusinessModel: {},
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
      isSubmitting: false,
      isNew: true,
    };
  },
  validations: {
    apiParams: {
      district: {},
      ward: {},
      city: {},
      name: { required },
      address: { required, maxLength: maxLength(400)},
      phoneNo: { isValidPhoneNumber },
      email: { isValidEmail },
      taxNo: {
        required,
        isValidTaxNo,
      },
    },
  },

  watch: {
    id: {
      async handler(currentId, prevId) {
        if (!currentId || currentId == prevId) return;

        const businessModel = this.isEditMode
          ? await this.getBusinessById(currentId)
          : this.initDataApiParams;

        this.archivedBusinessModel = cloneDeep(businessModel);

        this.apiParams = { ...businessModel };
        this.getCities();
      },
      immediate: true,
    },
    bindingTitle: {
      handler() {
        this.createBreadcrumb();
      },
      immediate: true,
    },
  },
  computed: {
    isEditMode() {
      return this.id || null;
    },
    bindingTitle() {
      const suffixTitle = 'khách hàng doanh nghiệp';
      return this.isEditMode
        ? `Cập nhật ${suffixTitle}`
        : `Thêm mới ${suffixTitle}`;
    },
    bindingTitleButton() {
      return this.isEditMode ? 'Cập nhật' : 'Tạo mới';
    },
    disableSubmit() {
      const hasError = this.$v.apiParams.$anyError;
      return this.isSubmitting || hasError;
    },
  },
  created() {},
  methods: {
    makeToastFaile,
    makeToastSuccess,
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: this.bindingTitle }]);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.apiParams[name];
      return $dirty ? !$error : null;
    },
    onSelect(node, type) {
      const mappingByLocation = {
        CITY: {
          handler: 'getDistrictByCityId',
          value: {
            city: node.id,
            district: null,
            ward: null,
          },
        },
        DISTRICT: {
          handler: 'getWardByDistrictId',
          value: {
            district: node.id,
            ward: null,
          },
        },
        WARD: {
          handler: '',
          value: {
            ward: node.id,
          },
        },
      };

      const { handler, value } = mappingByLocation[type];
      this.apiParams = Object.assign({}, this.apiParams, value);
      if (!handler) return;
      return this[handler](node.id);
    },
    async onUpsertBusiness() {
      const isValid = this.validateApiParam();
      if (!isValid) {
        return this.makeToastFaile(
          'Thông tin nhập không hợp lệ. Vui lòng kiểm tra lại.',
        );
      }
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      try {
        const upsertBusinessModel = Object.assign({}, this.apiParams, {
          id: this.isEditMode ? this.id : null,
        });

        const method = this.isEditMode ? 'put' : 'post';
        const response = await ApiService[method]('businesses', {
          ...upsertBusinessModel,
        });
        this.makeToastSuccess(response.message);
        this.$router.push({ name: 'list-business' });
      } catch (error) {
        this.handleErrorResponse(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    handleErrorResponse(error) {
      if (error.response) {
        this.makeToastFaile(
          error.response.data
            ? error.response.data.message
            : 'Có vấn đề xảy ra hãy thử lại',
        );
      } else {
        this.makeToastFaile(error.message || 'Có vấn đề xảy ra hãy thử lại');
      }
    },
    validateApiParam() {
      this.$v.apiParams.$touch();
      for (let key in Object.keys(this.$v.apiParams)) {
        // 2. Extract the input
        const input = Object.keys(this.$v.apiParams)[key];
        // 3. Remove special properties
        // if (input.includes('$')) return false;

        // 4. Check for errors
        if (!input.includes('$') && this.$v.apiParams[input].$error) {
          // 5. Focus the input with the error
          this.$refs[input].focus();

          // 6. Break out of the loop
          break;
        }
      }
      return !this.$v.apiParams.$anyError;
    },
    async getCities() {
      const res = await ApiService.get('city/');
      const cities = res.data.data;

      this.listCity = cloneDeep(cities);
      if (this.apiParams.district) {
        await this.getDistrictByCityId(this.apiParams.city);
      }
    },
    async getDistrictByCityId(cityId) {
      if (!cityId) return;
      const res = await ApiService.get(`city/${cityId}/district`);
      const districts = res.data.data;

      this.listDistrict = cloneDeep(districts);
      if (this.apiParams.ward) {
        await this.getWardByDistrictId(this.apiParams.district);
      }
    },
    async getWardByDistrictId(wardId) {
      if (!wardId) return;
      const res = await ApiService.get(`city/${wardId}/ward`);
      const wards = res.data.data;

      this.listWard = cloneDeep(wards);
    },
    async getBusinessById(businessId) {
      this.isSubmitting = true;
      try {
        const response = await ApiService.get(`businesses/${businessId}`);
        const business = response.data.data;

        return this.mappingBusinessModel(business);
      } catch (error) {
        this.handleErrorResponse(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    mappingBusinessModel(business = {}) {
      const defaultBusinessModel = { ...this.initDataApiParams };
      if (!Object.keys(business).length) return defaultBusinessModel;

      const businessAsPayload = Object.assign({}, business);

      const businessModel = {};

      for (const property in defaultBusinessModel) {
        businessModel[property] = businessAsPayload[property];
      }

      return businessModel;
    },
  },
};
</script>

<style lang="scss">
.business-upsert {
  .vue-treeselect__multi-value-item-container {
    display: none;
  }

  .vue-treeselect__input-container {
    padding-top: 0px;
  }

  .vue-treeselect__control {
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    height: 2.5rem;
  }

  .vue-treeselect--has-value .vue-treeselect__multi-value {
    margin-bottom: 2px;
  }
}
</style>
